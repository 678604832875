
import { Component, Prop, Vue } from 'vue-property-decorator'
import Gear from '@/interfaces/gear'

@Component
export default class GearDropdown extends Vue {
  @Prop()
  choices!: Gear[]

  @Prop()
  error!: string[] | undefined

  @Prop()
  maxIl!: number

  @Prop()
  minIl!: number

  @Prop()
  name!: string

  @Prop()
  value!: number

  get dropdown(): HTMLSelectElement {
    return this.$refs.dropdown as HTMLSelectElement
  }

  get gear(): Gear[] {
    return this.choices.filter((item: Gear) => item.id === this.value || (item.item_level <= this.maxIl && item.item_level >= this.minIl))
  }

  handleInput(): void {
    this.$emit('input', this.dropdown.value)
  }
}
